import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import type { Breakpoint } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { theme as aphTheme } from '@aph/component-library';
import { ErrorBoundary } from '@aph/components/error-boundary/error-boundary.container';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import theme from '~/styles/theme';
import { Footer } from '../common/footer/footer.component';
import { LowerNavigation } from '../common/main-navigation/lower-navigation.component';
import { TopNavigation } from '../common/main-navigation/top-navigation.component';
import {
  CONTENT_WIDTH,
  GRID_GUTTER,
  PageLayoutContainer,
  SIDEBAR_WIDTH,
} from './page-layout-container';

// we wrap the page layout with a theme that uses the breakpoints from the aph theme
// this is done so we don't have to use the useCompLibBreakpoints hook in every component (and avoid flickering)
const withBreakpointsFromAphTheme = createTheme({
  ...theme,
  breakpoints: {
    mqs: theme.breakpoints.mqs,
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      ...aphTheme.breakpoints.values,
      xxl: 1920,
    },
  },
});

type WaveBreakpoint = Exclude<Breakpoint, 'xs' | 'sm' | 'xsMid' | 'smMid'>;

type WaveHeight = 'small' | 'medium' | 'large';

interface LayoutProps {
  seo: NextSeoProps;

  /**
   * Content inside the "hero" slot will end up "inside" the wave area (the divider between the hero and the page content)
   */
  hero: React.ReactElement;
  /**
   * You need to tell the layout up-front the height of the wave area. This is done by providing preferably "small", "medium", or "large".
   * As an escape hatch a number (in pixels) is also accepted a well as an object with theme breakpoints from md and up.
   */
  wave: number | Record<WaveBreakpoint, number | WaveHeight | string> | WaveHeight;

  top?: React.ReactElement;

  /**
   * The "header" layout slot allows you to provide a custom header that will be used instead of the default one.
   */
  header?: React.ReactElement;

  /**
   * The "sidebar" layout slot allows you to provide content that displays to the left (on medium screens and up).
   */
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
}

// this is basically just aliases that we use for some common wave heights
const WAVE_HEIGHT = {
  small: 181,
  medium: 263,
  large: 374,
} as const;

const DIVIDER_HEIGHT = {
  xs: '18px',
  sm: '24px',
  md: '34px',
  lg: '40px',
  xl: '46px',
  xxl: '56px',
} as const;

const mapWaveValueToBreakpoints = (wave: LayoutProps['wave']) => {
  const breakpoints: Array<WaveBreakpoint> = ['md', 'lg', 'xl', 'xxl'];

  return breakpoints.reduce(
    (previous, breakpoint) => {
      let value;
      if (typeof wave === 'number') {
        value = wave;
      } else if (typeof wave === 'string') {
        value = WAVE_HEIGHT[wave];
      } else {
        value = wave[breakpoint];
      }

      return { ...previous, [breakpoint]: value };
    },
    {} as Record<WaveBreakpoint, number | string>,
  );
};

const toCssValue = (value: string | number) => (typeof value === 'number' ? `${value}px` : value);

export const PageLayout: React.FC<LayoutProps> = ({
  seo,
  top,
  header,
  hero,
  wave,
  sidebar,
  children,
}) => {
  const isLayoutWithSidebar = Boolean(sidebar);
  const isUsingCustomHeader = Boolean(header);
  const isLayoutWithoutWave = wave === 0;

  const getHeroHeightStyleProps = useCallback(() => {
    const values = mapWaveValueToBreakpoints(wave);

    return Object.entries(values).reduce((previous, [breakpoint, value]) => {
      return {
        ...previous,
        [breakpoint]: `calc(${DIVIDER_HEIGHT[breakpoint as keyof typeof DIVIDER_HEIGHT]
          } + ${toCssValue(value)})`,
      };
    }, {});
  }, [wave]);

  const getTopOfWaveStyleProps = useCallback(() => {
    const values = mapWaveValueToBreakpoints(wave);

    return Object.entries(values).reduce((previous, [breakpoint, value]) => {
      return {
        ...previous,
        [breakpoint]: `calc(-1 * (${DIVIDER_HEIGHT[breakpoint as keyof typeof DIVIDER_HEIGHT]
          } + ${toCssValue(value)}) + ${theme.spacing(4)})`,
      };
    }, {});
  }, [wave]);

  return (
    <>
      <NextSeo {...seo} />
      <ThemeProvider theme={withBreakpointsFromAphTheme}>
        {top ?? <InfoBar />}
        {isUsingCustomHeader ? (
          <PageLayoutContainer bgcolor={theme.palette['color/background/elevated']}>
            {header}
          </PageLayoutContainer>
        ) : (
          <>
            <PageLayoutContainer bgcolor={theme.palette['color/background/elevated']}>
              <TopNavigation />
            </PageLayoutContainer>
            <PageLayoutContainer
              bgcolor={theme.palette['color/background/elevated']}
              position="sticky"
              top={0}
              zIndex={theme.zIndex.appBar}
            >
              <LowerNavigation />
            </PageLayoutContainer>
          </>
        )}

        <ErrorBoundary>
          <Box
            position="relative"
            zIndex={1}
            sx={{
              isolation: 'isolate',
              '--TopOfWave': getTopOfWaveStyleProps(),
            }}
          >
            {isLayoutWithoutWave ? null : (
              <PageLayoutContainer bgcolor={theme.palette['color/background/visual']}>
                <Box height={getHeroHeightStyleProps()} overflow={{ md: 'hidden' }}>
                  <Stack
                    direction="row"
                    columnGap={GRID_GUTTER}
                    justifyContent={{ md: isLayoutWithSidebar ? undefined : 'center' }}
                  >
                    {isLayoutWithSidebar ? (
                      <Box
                        width={SIDEBAR_WIDTH}
                        visibility="hidden"
                        flexShrink={0}
                        display={{ xs: 'none', md: 'initial' }}
                      />
                    ) : null}
                    <Box width="100%" maxWidth={CONTENT_WIDTH} paddingTop={{ xs: 2, md: 4 }}>
                      {hero}
                    </Box>
                  </Stack>
                  {isLayoutWithoutWave ? null : (
                    <Box
                      component="svg"
                      position="absolute"
                      bottom={0}
                      left={0}
                      right={0}
                      zIndex={1}
                      preserveAspectRatio="none"
                      width="100%"
                      height={DIVIDER_HEIGHT}
                      viewBox="0 0 1440 72"
                      sx={{ transform: 'translateY(1px)' }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill={theme.palette['color/background/default']}
                        d="M456.562 0.75C218.437 0.75 60.5 22.7944 0 33.5625V72H1440V39.1875C1432.8 40.5619 1364.47 52.3125 1179.38 52.3125C948 52.3125 730.688 0.75 456.562 0.75Z"
                      />
                    </Box>
                  )}
                </Box>
              </PageLayoutContainer>
            )}

            <PageLayoutContainer marginTop={isLayoutWithoutWave ? 4 : '1px'}>
              <Stack
                direction="row"
                columnGap={GRID_GUTTER}
                justifyContent={{ md: isLayoutWithSidebar ? undefined : 'center' }}
              >
                {isLayoutWithSidebar ? (
                  <Box
                    position="relative"
                    display={{ xs: 'none', md: 'initial' }}
                    top={isLayoutWithoutWave ? 0 : getTopOfWaveStyleProps()}
                    width={SIDEBAR_WIDTH}
                    overflow="hidden"
                    flexShrink={0}
                  >
                    <Box
                      position="relative"
                      zIndex={2}
                      borderRadius={6}
                      paddingY={2}
                      paddingX={1}
                      bgcolor={theme.palette['color/background/elevated']}
                    >
                      {sidebar}
                    </Box>
                  </Box>
                ) : null}

                <Box maxWidth={CONTENT_WIDTH} width="100%">
                  <Box>{children}</Box>
                </Box>
              </Stack>
            </PageLayoutContainer>
          </Box>
        </ErrorBoundary>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export const PageLayoutWithoutWave: React.FC<Omit<LayoutProps, 'hero' | 'wave'>> = (props) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <PageLayout {...props} hero={<></>} wave={0} />;
};
